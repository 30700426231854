import * as React from "react"
import { useState, useCallback } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/layout/Seo"
import FileUpload from "../components/form/FileUpload"
import Map from "../components/common/Map"
import AdditionalForms from "../components/common/AdditionalForms"
import InputField from "../components/form/InputField"
import FormWrapper from "../components/form/FormWrapper"
import useFormState from "../components/form/hooks/useFormState"
import AsAdvertisedOn from "../components/common/AsAdvertisedOn"
import HaveQuestions from "../components/common/HaveQuestions"
import HowItWorks from "../components/common/HowItWorks"
import WhatAreYourOptions from "../components/common/WhatAreYourOptions"

const JewelryQuoteForm = () => {
  const CANONICAL_PATH_NAME = "/what-is-my-jewelry-worth/"
  if (typeof window !== `undefined` && window && window.location.pathname !== CANONICAL_PATH_NAME) {
    window.history.replaceState(null, null, CANONICAL_PATH_NAME + (window.location?.search || ""))
  }
  const [form, setForm, updateForm] = useFormState({
    name: "",
    phone: "",
    email: "",
    description: "",
    photos: [],
  })
  const updateUploadedFiles = useCallback(
    files =>
      setForm(f => {
        return { ...f, photos: files }
      }),
    [setForm]
  )
  return (
    <Layout className={"mx-auto"} breadcrumbs={false}>
      <Seo
        title="What is my jewelry worth? Jewelry Quote Form"
        description="Discover the true value of your jewelry at SellUsYourJewelry.com. Find out what your precious pieces are worth with our expert appraisal services."
        canonical={CANONICAL_PATH_NAME}
      />

      <section
        id="Watch Instant Quote Form"
        className="bg-gradient-to-tl  from-[#f5edcc] to-white pt-20 md:pt-5 lg:pt-0 py-10 md:py-20"
      >
        <div className="text-center text-3xl font-black text-[#816230] md:text-5xl xl:text-6xl w-full inline-block pb-5 lg:py-10 uppercase px-5">
          THE SOLUTION <span className="font-normal">to selling your jewelry</span>
        </div>
        <div className="flex flex-col items-center justify-center lg:flex-row">
          <div className="flex w-10/12 mx-auto flex-col justify-center items-center xl:px-20">
            <FormWrapper
              className={"bg-white p-5 2xl:w-10/12 pl-5 2xl:pl-[25%] relative rounded-xl shadow-xl"}
              name={"What is my jewelry worth"}
              action={"/thank-you/"}
              form={form}
              category={"ring"}
            >
              <div className="flex items-center flex-col md:flex-row 2xl:min-w-[500px] w-full 2xl:w-1/3 justify-center 2xl:absolute -bottom-[6%] 2xl:right-[calc(60%+5vw)] z-10 px-10">
                <StaticImage
                  src={"../images/sell-my-jewelry-form-header.png"}
                  alt={"Watch Quote Form image"}
                  loading={"lazy"}
                  placeholder={"none"}
                  className="w-full md:w-1/2 2xl:w-full"
                />
              </div>
              <h1 className="text-center w-full text-3xl px-5 uppercase hidden 2xl:absolute top-10 left-5 2xl:max-w-[28%] 2xl:block ">
                WHAT IS MY Jewelry WORTH?
              </h1>
              <p className="text-center 2xl:absolute top-40 2xl:px-5 left-5 2xl:max-w-[28%] 2xl:block">
                Fill out the simple form below to receive a free watch quote!
              </p>

              <div className="flex flex-col space-y-2">
                <div className="flex flex-col space-y-4">
                  <InputField
                    name={"name"}
                    label={"Name"}
                    form={form}
                    onChange={updateForm}
                    required={true}
                  />
                  <InputField
                    name={"phone"}
                    label={"Phone"}
                    form={form}
                    onChange={updateForm}
                    required={true}
                  />
                  <InputField
                    name={"email"}
                    label={"E-Mail"}
                    form={form}
                    onChange={updateForm}
                    required={true}
                  />
                  <InputField
                    name={"description"}
                    label={"Description"}
                    type={"textarea"}
                    rows={"5"}
                    form={form}
                    onChange={updateForm}
                  />
                  <FileUpload
                    id={"photos"}
                    label={"Add photos"}
                    name={"photos"}
                    accept={".jpg,.png,.jpeg"}
                    updateFilesCb={updateUploadedFiles}
                    multiple
                  />
                </div>
                * Max. file size: 40 MB, Max. files: 5.
                <br />
                <br />
                Please attach photos of items & appraisals/certificates. The red 'X's are normal.
              </div>
              <div className="w-full flex items-center justify-center 2xl:justify-start">
                <button className="bg-[#816230] hover:bg-accentMd duration-200 text-white px-7 py-3 rounded-full my-5">
                  SUBMIT FORM
                </button>
              </div>
            </FormWrapper>
          </div>
        </div>
      </section>
      <WhatAreYourOptions />

      <HowItWorks className={"w-10/12 max-w-[1366px] mx-auto"} />

      <AdditionalForms />

      <section
        name="Easy Ship Box"
        className="w-10/12 max-w-[1366px] mx-auto flex flex-col justify-center"
      >
        <p className="w-full p-10 text-lg">
          Selling old family jewelry can often be an emotional experience, but when you work with
          SellUsYourJewelry.com, we can make it a pleasant experience. We buy an estate and modern
          jewelry, including rings, necklaces, earrings, pins, cuff links, bracelets, and pendants.
          We also accept old jewelry that is made with platinum, 18K gold, 14K gold, diamonds, and
          other precious gemstones. Instead of saving a family member’s jewelry and having it take
          up space in your closet or jewelry box, we give you the option of selling your jewelry to
          us and making a profit. Interested in learning more? Fill out the information below and we
          will send you a free jewelry quote. Get started with SellUsYourJewelry.com today!
        </p>

        <br />
        <Link to="/ez-ship-box-request-form/" className="hoverScale flex w-full justify-center ">
          <StaticImage
            src={"../images/Shipping-Box-request.jpg"}
            alt={"Get an Appraisal for Jewelry - Shipping"}
            loading={"eager"}
          />
        </Link>
      </section>

      <section className="py-20">
        <AsAdvertisedOn />
      </section>

      <div className="w-full py-10 shadow-lg" />

      <HaveQuestions />

      <section className={"mt-16"}>
        <Map />
      </section>
    </Layout>
  )
}

export default JewelryQuoteForm
